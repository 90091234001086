<template>
<v-row no-gutters>      
    <v-col>
    <div id="leavelist">
                
        <!-- Üst Bilgiler Kartı -->
        <v-card class="mb-4 pa-3" outlined>
          
          <v-card-title class="d-flex align-center py-2 px-3" style="border-radius: 4px;">
            <v-icon large left class="mr-2" color="success">{{ icons.mdiAccountDetails }}</v-icon>
            <span class="text-subtitle-1 font-weight-bold">Personel Bilgileri</span>
          </v-card-title>
          <v-divider class="mb-2"></v-divider>
        
          <v-row dense class="pa-3">
            <v-col cols="6" class="mb-1">
              <span class="font-weight-bold text-grey-darken-2">Departman:</span>
              <span class="ml-1 text-body-2">{{ detailInfo.DEPARTMAN }}</span>
            </v-col>
            <v-col cols="6" class="mb-1">
              <span class="font-weight-bold text-grey-darken-2">Görev:</span>
              <span class="ml-1 text-body-2">{{ detailInfo.WORKTITLET }}</span>
            </v-col>
            <v-col cols="6" class="mb-1">
              <span class="font-weight-bold text-grey-darken-2">Adı Soyadı:</span>
              <span class="ml-1 text-body-2">{{ detailInfo.DISPLAY }}</span>
            </v-col>
            <v-col cols="6" class="mb-1">
              <span class="font-weight-bold text-grey-darken-2">Kıdem Yılı:</span>
              <span class="ml-1 text-body-2">{{ detailInfo.KIDEMYILI }}</span>
            </v-col>
            <v-col cols="6" class="mb-1">
              <span class="font-weight-bold text-grey-darken-2">Kalan Gün:</span>
              <span class="ml-1 text-body-2">{{ detailInfo.KALANGUN }}</span>
            </v-col>
            <v-col cols="6" class="mb-1">
              <span class="font-weight-bold text-grey-darken-2">Kullanılan Gün:</span>
              <span class="ml-1 text-body-2">{{ detailInfo.KULLANILANGUN }}</span>
            </v-col>
            <v-col cols="6" class="mb-1">
              <span class="font-weight-bold text-grey-darken-2">Sistem Dışı Gün:</span>
              <span class="ml-1 text-body-2">{{ detailInfo.ISTEMDISIGUN }}</span>
            </v-col>
            <v-col cols="6" class="mb-1">
              <span class="font-weight-bold text-grey-darken-2">Toplam Hak Edilen Gün:</span>
              <span class="ml-1 text-body-2">{{ detailInfo.TOPHAKEDILEN }}</span>
            </v-col>
            <v-col cols="6" class="mb-1">
              <span class="font-weight-bold text-grey-darken-2">Başlangıç Tarihi:</span>
              <span class="ml-1 text-body-2">{{ detailInfo.STARTDATE }}</span>
            </v-col>
          </v-row>
        </v-card>
        
        
        <!-- list filters -->

        <v-card
        tile
        :flat="$vuetify.breakpoint.mdAndDown"
        :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'" >
        <v-toolbar
            v-if="!$vuetify.breakpoint.smAndDown"
            :color="$vuetify.breakpoint.mdAndDown ? 'transparent' : 'transparent'"
            flat
            :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
        >
            <v-toolbar-title class="text-h6 font-weight-medium">
              
            <v-icon large left>
                {{ icons.mdiViewList }}
            </v-icon>
            İzin Taleplerim
            </v-toolbar-title>    
            <v-spacer></v-spacer>   
            <v-btn
           
            :class="{
              'bg-gradient-nightDark': !isDark,
              'bg-gradient-primary': isDark, // isDark ise farklı bir stil uygula
              'text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 white--text': true
            }"
            depressed
            x-large
            @click="addLeave()"
            @mouseenter="activeHover = true" @mouseleave="activeHover = false"
            >
            <v-icon class="mr-2">{{ icons.mdiPlus }}</v-icon>
            <span>İzin Ekle</span>
            <span  v-if="activeHover"> <img src="@/assets/images/misc/tukas-yaprak.png" width="20" > </span>   
            </v-btn>              
        </v-toolbar>

        <v-expansion-panels v-else v-model="panel" flat tile>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="text-body-2 font-weight-medium">
                <v-icon left>
                  {{ icons.mdiViewList }}
                </v-icon>
                İzin Taleplerim
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-0">
              <v-divider></v-divider>
              <v-list class="pa-0" color="grey">
                <v-list-item @click="addLeave()">
                  <v-list-item-content>
                    <v-list-item-title>İzin Ekle</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>{{ icons.mdiPlusThick }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-divider></v-divider>                  
             
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-row>
            <v-col
                cols="12"
                sm="2"
                class="ma-2"
            >
            <v-select
            v-model="selectedYear"
            :items="years"
            label="Yıl Seçiniz"
            @change="fetchLeaves"
            ></v-select>

            </v-col>

            <v-col
                cols="12"
                sm="3"
                class="ma-2"
            >
            <v-select
            v-model="selectedMonth"
            :items="months"
            label="Ay Seçiniz"
            @change="fetchLeaves"
            ></v-select>
            </v-col>
            </v-row>

        <!-- table -->
        <v-data-table
            
            :headers="headers"
            :items="leaves"
            :items-per-page="15"
            fixed-header                      
            :loading="loading"
        >
            <!-- Text -->

            <template v-slot:item.LEAVEDESC="{ item }">
            <v-chip link outlined small :color="getColor(item.COLOR)">
                {{ item.LEAVEDESC }}
            </v-chip>
            </template>
            <template v-slot:item.CONFIRMSTAT="{ item }">
            <v-chip link outlined small :color="getStatusColor(item.CONFIRMSTAT)">
                {{ item.CONFIRMSTAT }}
            </v-chip>
            </template>
         <!--    <template #[`item.actions`]="{ item }">

            <v-tooltip bottom color="error">
                <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="item.CONFIRMSTAT === 'Bekliyor'" depressed color="error" x-small plain v-bind="attrs" @click="leaveDelete(item)" v-on="on">
                    <v-icon small>
                    {{ icons.mdiDelete }}
                    </v-icon>
                </v-btn>
                </template>
                <span>İzin Sil</span>
            </v-tooltip>
            </template> -->
        </v-data-table>
        </v-card>
    </div>
    </v-col>
</v-row>
  
  </template>
  
  <script>
  import useAppConfig from '@core/@app-config/useAppConfig'
  import store from '@/store'
  import VSnackbars from 'v-snackbars'
import { ref, watch } from '@vue/composition-api'
import router from '@/router'
import {
  mdiClipboardList,
  mdiClose,
  mdiDownload,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiOrderNumericAscending,
  mdiPlusThick,
  mdiRefresh,
  mdiSend,mdiViewList,
  mdiDelete,
  mdiAccountDetails
} from '@mdi/js'
  export default {

    components: {   
    VSnackbars, 
  },
    data() {
      return {    
        detailInfo: {
        DEPARTMAN: "",
        DISPLAY: "",
        KALANGUN: "",
        KIDEMYILI: "",
        KULLANILANGUN: "",
        ISTEMDISIGUN: "",
        TOPHAKEDILEN: "",
        STARTDATE: "",
        WORKTITLET: "",

        },  

     
        selectedYear: new Date().getFullYear(),
        selectedMonth: (new Date().getMonth() + 1).toString().padStart(2, '0'), // 01-12
        years: [],
        months: [
          { text: 'Hepsi', value: '' },
          { text: 'Ocak', value: '01' },
          { text: 'Şubat', value: '02' },
          { text: 'Mart', value: '03' },
          { text: 'Nisan', value: '04' },
          { text: 'Mayıs', value: '05' },
          { text: 'Haziran', value: '06' },
          { text: 'Temmuz', value: '07' },
          { text: 'Ağustos', value: '08' },
          { text: 'Eylül', value: '09' },
          { text: 'Ekim', value: '10' },
          { text: 'Kasım', value: '11' },
          { text: 'Aralık', value: '12' },
        ],
        leaves: [],
        loading: false ,// Yükleniyor durumu
        activeHover : false,       
        headers: [
        { text: 'İzin Açıklaması', value: 'LEAVENUM' },
        { text: 'Başlangıç Tarihi', value: 'FIRSTDATEDT' },
        { text: 'Bitiş Tarihi', value: 'LASTDATEDT' },
        { text: 'Toplam Gün', value: 'TOTLEAVEDAY' },
        { text: 'İzin Tipi', value: 'LEAVEDESC' },
        { text: 'Durum', value: 'CONFIRMSTAT' },
       /*  {
        text: 'İşlemler',
        value: 'actions',
        align: 'right',
        sortable: false,
      }, */
      ],
        icons: {
        mdiEye,
        mdiClose,
        mdiClipboardList,
        mdiOrderNumericAscending,
        mdiFilter,
        mdiDownload,
        mdiSend,
        mdiFilterOff,
        mdiRefresh,
        mdiPlusThick,mdiViewList,mdiDelete,
        mdiAccountDetails
      },
      };
    },
    setup() {
    const { isDark } = useAppConfig() // Doğrudan setup içinde kullan

    return { isDark }
    },
    methods: {
    async fetchLeaves() {
        debugger
        this.loading = true; // Yükleniyor durumunu başlat
        const year = this.selectedYear;
        const month = this.selectedMonth;

        const response = await store.dispatch('postMethod', {
        method: 'getPersLeaves',
        peryear: year,
        permonth: month
      });
      debugger
         this.loading = false; // Yükleniyor durumunu durdur
      if (response && response.detail ) {
        debugger
        if (response.error === 0) {
            this.leaves = response.detail[1].LEAVES;
            console.log("responsee",this.leaves)
               // Üst bilgileri güncelle
            this.detailInfo.DEPARTMAN = response.detail[1].DEPARTMENTT || "Bilinmiyor";
            this.detailInfo.DISPLAY = response.detail[1].DISPLAY || "Bilinmiyor";
            this.detailInfo.KALANGUN = response.detail[1].LVGRPKALANGUN || "Bilinmiyor";
            this.detailInfo.KIDEMYILI = response.detail[1].LVGRPKIDEMYILI || "Bilinmiyor";
            this.detailInfo.KULLANILANGUN = response.detail[1].LVGRPKULLANILANGUN  || "Bilinmiyor";
            this.detailInfo.ISTEMDISIGUN  = response.detail[1].LVGRPSISTEMDISIGUN  || "Bilinmiyor";
            this.detailInfo.TOPHAKEDILEN  = response.detail[1].LVGRPTOPHAKEDILEN  || "Bilinmiyor";
            this.detailInfo.STARTDATE  = response.detail[1].STARTDATE  || "Bilinmiyor";
            this.detailInfo.WORKTITLET  = response.detail[1].WORKTITLET  || "Bilinmiyor";
          } else {
            this.leaves = [];
            console.error(response.msg);
          }
      }
      },

    getColor(color) {
      // Burada COLOR değerine göre uygun rengi döndür
      const colorMap = {
        info: '#2196F3', // Mavi
        success: '#4CAF50', // Yeşil
        error: '#F44336', // Kırmızı
        warning: '#FF9800', // Turuncu
      };
      return colorMap[color] || '#B0BEC5'; // Varsayılan gri
    },

    getStatusColor(status) {
      const statusColorMap = {
        Bekliyor: '#FF9800',
        Onaylandı: '#4CAF50',
        Reddedildi: '#F44336',
      };
      return statusColorMap[status] || 'grey'; // Varsayılan gri
    },
    addLeave(){
      router.push({ name: 'add-leave' })
    },

   

    },
    mounted() {
      // Yıllar listesini doldurun
      this.years = Array.from({ length: 10 }, (v, i) => new Date().getFullYear() - i);
      this.fetchLeaves(); // İlk yüklemede izinleri al
    },
    
  };
  </script>
  
  <style scoped>
  /* Stil ayarlamaları buraya eklenebilir */
  </style>
  